@keyframes fadeInMove1 {
    from {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-7deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-3deg);
    }
}
@keyframes fadeOutMove1 {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-3deg);
    }
    to {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-7deg);
    }
}
@keyframes fadeInMove2 {
    from {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-26deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
}
@keyframes fadeOutMove2 {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
    to {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-26deg);
    }
}
@keyframes fadeInMove3 {
    from {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(15deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(20deg);
    }
}
@keyframes fadeOutMove3 {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(20deg);
    }
    to {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(15deg);
    }
}
@keyframes fadeInMove4 {
    from {
        opacity: 0;
        transform: translateX(-200px) translateY(-150px) rotate(-5deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(10deg);
    }
}
@keyframes fadeOutMove4 {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(10deg);
    }
    to {
        opacity: 0;
        transform: translateX(-200px) translateY(-150px) rotate(-5deg);
    }
}

@keyframes enterPageYarns {
    from {
        opacity: 0;
        transform: translateX(0px) translateY(1000px);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
}
@keyframes exitPageYarns {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateX(0px) translateY(-1000px);
    }
}

.instaPostyarns-container {
    width: 100%;
    position: relative;
}

.instaPost1yarns {
    animation: fadeInMove1 0.6s ease-out forwards;
    position: absolute;
    top: 10%;
    right: 6%;
    width: 250px;
}
.instaPost1yarns-exit {
    animation: fadeOutMove1 0.6s ease-out forwards;
}

.instaPost2yarns {
    animation: fadeInMove2 0.9s ease-out forwards;
    position: absolute;
    top: 28%;
    left: 5%;
    width: 250px;
}
.instaPost2yarns-exit {
    animation: fadeOutMove2 0.6s ease-out forwards;
}
.instaPost3yarns {
    position: absolute;
    animation: fadeInMove3 0.8s ease-out forwards;
    top: 13%;
    left: 30%;
    width: 250px;
}
.instaPost3yarns-exit {
    animation: fadeOutMove3 0.6s ease-out forwards;
}
.instaPost4yarns {
    position: absolute;
    animation: fadeInMove4 0.8s ease-out forwards;
    top: 37%;
    left: 50%;
    width: 250px;
}
.instaPost4yarns-exit {
    animation: fadeOutMove4 0.6s ease-out forwards;
}

.yarns-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.yarns-relative-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.yarns {
    position: absolute;
}
.yarn1 {
    animation: enterPageYarns 0.7s ease-out forwards;
    left: 65%;
    top: 50%;
}
.yarn2 {
    animation: enterPageYarns 0.6s ease-out forwards;
    left: 68%;
    top: -10%;
}
.yarn3 {
    animation: enterPageYarns 0.9s ease-out forwards;
    left: 43%;
    top: 20%;
}
.yarn4 {
    animation: enterPageYarns 0.7s ease-out forwards;
    left: 4%;
    top: 10%;
}
.yarn5 {
    animation: enterPageYarns 0.6s ease-out forwards;
    left: 16%;
    top: 55%;
}
.yarn1-exit {
    animation: exitPageYarns 0.7s ease-out forwards;
}
.yarn2-exit {
    animation: exitPageYarns 0.5s ease-out forwards;
}
.yarn3-exit {
    animation: exitPageYarns 0.9s ease-out forwards;
}
.yarn4-exit {
    animation: exitPageYarns 0.7s ease-out forwards;
}
.yarn5-exit {
    animation: exitPageYarns 0.5s ease-out forwards;
}
/* Media query pour les écrans de moins de 1000px */

@media (max-width: 960px) {
    
    .instaPost-container {
        height: 100%;
    }
    .instaPost1yarns {
        top: 3%;
        right: 6%;
        width: 200px;
    }
    .instaPost2yarns {
        top: 38%;
        left: 10%;
        width: 200px;
    }
    .instaPost3yarns {
        top: 3%;
        left: 30%;
        width: 200px;
    }
    .instaPost4yarns {
        top: 42%;
        left: 50%;
        width: 200px;
    }
    }
    
@media (max-width: 600px) {
    
.yarns img{
    width: 80%;
}
    .instaPost-container {
        height: 100%;
    }
    .instaPost1yarns {
        top: 3%;
        right: 6%;
        width: 170px;
    }
    .instaPost2yarns {
        top: 57%;
        left: 10%;
        width: 170px;
    }
    .instaPost3yarns {
        top: 17%;
        left: 10%;
        width: 170px;
    }
    .instaPost4yarns {
        top: 42%;
        left: 50%;
        width: 170px;
    }
    }