.scroll-status {
    position: fixed;
    bottom: 50%;
    left: 20px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: white;
}
