@keyframes fadeInMove1 {
    from {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-7deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-3deg);
    }
}
@keyframes fadeOutMove1 {
    to {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-7deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-3deg);
    }
}
@keyframes fadeInMove2 {
    from {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-26deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
}
@keyframes fadeOutMove2 {
    to {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-26deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
}
@keyframes fadeInMove3 {
    from {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(15deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(20deg);
    }
}
@keyframes fadeOutMove3 {
    to {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(15deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(20deg);
    }
}

@keyframes enterPageHolds {
    from {
        opacity: 0;
        transform: translateX(0px) translateY(1000px);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
}
@keyframes exitPageHolds {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateX(0px) translateY(-1000px);
    }
}

.instaPost-container {
    width: 100%;
    position: relative;
}

.instaPost1Climb {
    animation: fadeInMove1 0.6s ease-out forwards;
    position: absolute;
    top: 6%;
    right: 3%;
    width: 250px;
}
.instaPost1Climb-exit {
    animation: fadeOutMove1 0.6s ease-out forwards;
}

.instaPost2Climb {
    animation: fadeInMove2 0.9s ease-out forwards;
    position: absolute;
    top: 18%;
    left: 11.3%;
    width: 250px;
}
.instaPost2Climb-exit {
    animation: fadeOutMove2 0.6s ease-out forwards;
}
.instaPost3Climb {
    position: absolute;
    animation: fadeInMove3 0.8s ease-out forwards;
    top: 33%;
    left: 40%;
    width: 250px;
}
.instaPost3Climb-exit {
    animation: fadeOutMove3 0.6s ease-out forwards;
}

.holds-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.holds-relative-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.holds {
    position: absolute;
}
.hold1 {
    animation: enterPageHolds 0.7s ease-out forwards;
    left: 4%;
    top: 3%;
}
.hold1-exit {
    animation: exitPageHolds 0.7s ease-out forwards;
}
.hold2 {
    animation: enterPageHolds 0.4s ease-out forwards;
    left: 25%;
    top: 5%;
}
.hold2-exit {
    animation: exitPageHolds 0.4s ease-out forwards;
}
.hold3 {
    animation: enterPageHolds 0.9s ease-out forwards;
    left: 73%;
    top: 40%;
}
.hold3-exit {
    animation: exitPageHolds 0.9s ease-out forwards;
}
.hold4 {
    animation: enterPageHolds 0.7s ease-out forwards;
    left: 90%;
    top: 80%;
}
.hold4-exit {
    animation: exitPageHolds 0.7s ease-out forwards;
}
.hold5 {
    animation: enterPageHolds 0.4s ease-out forwards;
    left: 36%;
    top: 73%;
}
.hold5-exit {
    animation: exitPageHolds 0.4s ease-out forwards;
}
.hold6 {
    animation: enterPageHolds 0.9s ease-out forwards;
    left: 10%;
    top: 70%;
}
.hold6-exit {
    animation: exitPageHolds 0.9s ease-out forwards;
}
.hold7 {
    animation: enterPageHolds 0.7s ease-out forwards;
    left: 78%;
    top: -5%;
}
.hold7-exit {
    animation: exitPageHolds 0.7s ease-out forwards;
}
.hold8 {
    animation: enterPageHolds 0.4s ease-out forwards;
    left: 48%;
    top: 30%;
}
.hold8-exit {
    animation: exitPageHolds 0.4s ease-out forwards;
}

@media (max-width: 960px) {
    .instaPost1Climb {
        top: 13%;
        right: 10%;
        width: 200px;
    }
    .instaPost2Climb {
        top: 18%;
        left: 11.3%;
        width: 200px;
    }
    .instaPost3Climb {
        top: 45%;
        left: 40%;
        width: 200px;
    }
}
@media (max-width: 600px) {
    .instaPost1Climb {
        top: 7%;
        right: 10%;
        width: 170px;
    }
    .instaPost2Climb {
        top: 28%;
        left: 11.3%;
        width: 170px;
    }
    .instaPost3Climb {
        top: 53%;
        left: 40%;
        width: 170px;
    }
}