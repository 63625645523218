@keyframes fadeInMove1Concert {
    from {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-8deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-8deg);
    }
}
@keyframes fadeOutMove1Concert {
    to {
        opacity: 0;
        transform: translateX(150px) translateY(-100px) rotate(-8deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-8deg);
    }
}
@keyframes fadeInMove2Concert {
    from {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-20deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
}
@keyframes fadeOutMove2Concert {
    to {
        opacity: 0;
        transform: translateX(-150px) translateY(100px) rotate(-20deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(-20deg);
    }
}
@keyframes fadeInMove3Concert {
    from {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(40deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(40deg);
    }
}
@keyframes fadeOutMove3Concert {
    to {
        opacity: 0;
        transform: translateX(200px) translateY(300px) rotate(40deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(40deg);
    }
}
@keyframes fadeInMove4Concert {
    from {
        opacity: 0;
        transform: translateX(-100px) translateY(-100px) rotate(10deg);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(10deg);
    }
}
@keyframes fadeOutMove4Concert {
    to {
        opacity: 0;
        transform: translateX(-100px) translateY(-100px) rotate(10deg);
    }
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px) rotate(10deg);
    }
}

@keyframes enterPageVynils {
    from {
        opacity: 0;
        transform: translateX(0px) translateY(1000px);
    }
    to {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
}
@keyframes exitPageVynils {
    from {
        opacity: 1;
        transform: translateX(0px) translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateX(0px) translateY(-1000px);
    }
}


.polaroids-container {
    width: 100%;
    position: relative;
}

.polaroid1Concert {
    animation: fadeInMove1Concert 0.4s ease-out forwards;
    position: absolute;
    top: 6%;
    left: 70%;
    width: 220px;
}
.polaroid1Concert-exit {
    animation: fadeOutMove1Concert 0.4s ease-out forwards;
}
.polaroid2Concert {
    animation: fadeInMove2Concert 0.9s ease-out forwards;
    position: absolute;
    top: 18%;
    left: 3%;
    width: 240px;
}
.polaroid2Concert-exit {
    animation: fadeOutMove2Concert 0.9s ease-out forwards;
}
.polaroid3Concert {
    position: absolute;
    animation: fadeInMove3Concert 0.8s ease-out forwards;
    top: 33%;
    left: 57.4%;
    width: 200px;
}
.polaroid3Concert-exit {
    animation: fadeOutMove3Concert 0.8s ease-out forwards;
}
.polaroid4Concert {
    position: absolute;
    animation: fadeInMove4Concert 0.6s ease-out forwards;
    top: 7.5%;
    left: 27.3%;
    width: 300px;
}
.polaroid4Concert-exit {
    animation: fadeOutMove4Concert 0.6s ease-out forwards;
}

.polaroid-text-concert {
    font-family: 'Montaga';
    font-size: 24px;
    text-align: center;
    margin: 0;
}

.polaroid-original-text-concert {
    font-family: 'Just Another Hand';
    font-size: 21px;
    margin: 0;
}

.vynils-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.vynils-relative-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.vynils {
    position: absolute;
}

.vynil1 {
    animation: enterPageVynils 0.7s ease-out forwards;
    width: 300px;
    left: 11%;
}
.vynil2 {
    animation: enterPageVynils 0.8s ease-out forwards;
    width: 200px;
    top: 70%;
    left: 30%;
}
.vynil3 {
    animation: enterPageVynils 0.9s ease-out forwards;
    width: 280px;
    right: 10%;
    bottom: 10%;
}
.vynil4 {
    animation: enterPageVynils 0.6s ease-out forwards;
    width: 350px;
    left: 47%;
    top: 19%;
}
.vynil1-exit {
    animation: exitPageVynils 0.7s ease-out forwards;
}
.vynil2-exit {
    animation: exitPageVynils 0.5s ease-out forwards;
}
.vynil3-exit {
    animation: exitPageVynils 0.9s ease-out forwards;
}
.vynil4-exit {
    animation: exitPageVynils 0.7s ease-out forwards;
}
/* Media query pour les écrans de moins de 1000px */
@media (max-width: 960px) {
    .polaroid-text-concert {
        font-size: 14px;
    }
    .polaroid-original-text-concert {
        font-size: 20px;
    }
    .polaroids-container {
        height: 100%;
    }
    .polaroid1Concert {
        top: 6%;
        left: 20%;
        width: 250px;
    }
    .polaroid2Concert {
        top: 43%;
        left: 60%;
        width: 250px;
    }
    .polaroid3Concert {
        top: 46%;
        left: 23%;
        width: 250px;
    }
    .polaroid4Concert {
        top: -3%;
        left: 60%;
        width: 250px;
    }
}
@media (max-width: 600px) {
    .polaroid-text-concert {
        font-size: 14px;
    }
    .polaroid-original-text-concert {
        font-size: 18px;
    }
    .polaroids-container {
        height: 100%;
    }
    .polaroid1Concert {
        top: 2%;
        left: 50%;
        width: 190px;
    }
    .polaroid2Concert {
        top: 37%;
        left: 54%;
        width: 190px;
    }
    .polaroid3Concert {
        top: 53%;
        left: 17%;
        width: 190px;
    }
    .polaroid4Concert {
        top: 18%;
        left: 5%;
        width: 210px;
    }
}
