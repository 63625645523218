@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Montaga&display=swap');

.body-page {
    width: 60%;
    margin: auto;
}

.hobby-page {
    width: 100%;
    height: calc(100vh - 106px);
    overflow: clip;
}
@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.scroll-arrow {
    animation: bounce 2s infinite;
}

@keyframes text-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes text-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 1300px) {
    .body-page {
        width: 90%;
    }
}



.title-container {
    animation: text-fadeIn 0.8s ease-out forwards;
    width: 100%;
    height: 20%;
}

.title-container-exit {
    animation: text-fadeOut 0.8s ease-out forwards;
    width: 100%;
    height: 20%;
}
.content-container {
    width: 100%;
    position: relative;
    display: flex;
    height: 80%;
}

@media (max-width: 960px) {
    .content-container {
        flex-direction: column;
    }
    .title-container div div:nth-child(2),.title-container-exit div div:nth-child(2) {
        left: 30px;
        top: 40px;
    }
}
@media (max-width: 600px) {
    .content-container {
        flex-direction: column;
        height: 88%;
    }
    .title-container{
        height: 12%;
    }
    .title-container div div:nth-child(2),.title-container-exit div div:nth-child(2) {
        left: 20px;
        top: 25px;    
        -webkit-text-stroke: 1px white;
    }
}