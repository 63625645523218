.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 70%;
    margin:auto;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .buttons .styled-button:not(:last-child) {
    margin-right: 20px;
  }
  
  
@media (max-width: 1300px) {
  .navbar {
      width: 90%;
  }
}